import React from 'react'
import ProgressBar from 'src/components/ProgressBar'
import Navbar from 'src/sections/Navbar'
import Breadcrumbs from 'src/sections/Breadcrumbs'
import LocationsList from 'src/sections/LocationsList'
import EmptyAnnouncement from 'src/sections/EmptyAnnouncement'
import Layout from 'src/layout'
import SEO from 'src/components/SEO'
import createDateComponent from 'src/utils/createDateComponent'

const createLocations = (announcements: any[]) => {
  return announcements.map(announcement => {
    return {
      location: announcement.city,
      slug: announcement.slug,
      date: {
        date_start: createDateComponent(announcement.date.start),
        date_end: createDateComponent(announcement.date.end)
      }
    }
  })
}

const AnnouncementsPage = (props: any) => {
  const exposition = props.pageContext.exposition
  const locations = createLocations(exposition.announcements)

  return (
    <Layout>
      <SEO url={props.location.href} />
      <ProgressBar />
      <main>
        <div className="desktop">
          <Navbar />
        </div>
        <Breadcrumbs
          announcement
          url={exposition.slug}
          title={exposition.title}
        />
        {locations.length > 0
          ? (<LocationsList
              announcement
              pathname={props.location.pathname}
              locations={locations}
            />)
        : <EmptyAnnouncement />
      }
      </main>
    </Layout>
  )
}

export default AnnouncementsPage
