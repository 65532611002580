import React from 'react'
import H3 from 'src/components/Typography/H3'
import Wrapper from './style'

const EmptyAnnouncement:React.FC = () => {
  return (
    <Wrapper>
      <H3>Нет данных</H3>
    </Wrapper>
  )
}

export default EmptyAnnouncement
