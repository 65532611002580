import styled from 'styled-components'
import { up } from 'src/utils/media'

const Wrapper = styled.section`
  height: 100%;
  flex: 1 0 100%;
  flex-flow: column;
  padding: 10rem 0;
  text-align: center;
  text-transform: uppercase;
  justify-content: center;

  ${up('tablet')} {
    padding: 10rem 0;
  }

  h3 {
    color: ${props => props.theme.colors.subtitle};
  }
`

export default Wrapper
